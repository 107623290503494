import React, { useState } from "react";
import HeaderImage from "../assets/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth } from "@firebase/auth";
// import "./App.css";
import { Dots } from "react-activity";
// import "./App.css";
import "react-activity/dist/library.css";

export default function SignUpModal(props) {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayIndicator, setDisplayIndicator] = useState("");
  const navigate = useNavigate();

  const createUser = (auth, email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setTimeout(() => {
          navigate("/profile");
          setDisplayIndicator(false);
        }, 1000);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Error :: ", error);
      });
  };

  return (
    <div
      style={{
        height: "110vh",
        width: 500,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "white",
      }}
    >
      <div style={{ position: "absolute", top: 60 }}>
        <img src={HeaderImage} style={{ width: 200 }} />
      </div>
      <div
        style={{
          justifyContent: "center",
          marginTop: 150,
        }}
      >
        <p
          style={{
            color: "black",
            fontWeight: "600",
            fontSize: 50,
          }}
        >
          Sign Up
        </p>
      </div>
      <div style={{ width: "80%" }}>
        <p
          style={{
            color: "black",
            textAlign: "left",
            marginBottom: 5,
            fontWeight: "bold",
            fontSize: 12,
            paddingLeft: 5,
            color: "#A9A9A9",
          }}
        >
          First Name
        </p>
        <input
          style={{
            width: "95%",
            height: 45,
            borderWidth: 0,
            paddingLeft: 8,
            borderBottomWidth: 1,
            borderBottomColor: "#A9A9A9",
          }}
        />
      </div>
      <div style={{ width: "80%" }}>
        <p
          style={{
            color: "black",
            textAlign: "left",
            marginBottom: 5,
            fontWeight: "bold",
            fontSize: 12,
            paddingLeft: 5,
            color: "#A9A9A9",
          }}
        >
          Last Name
        </p>
        <input
          style={{
            width: "95%",
            height: 45,
            borderWidth: 0,
            paddingLeft: 8,
            borderBottomWidth: 1,
            borderBottomColor: "#A9A9A9",
          }}
        />
      </div>

      <div style={{ width: "80%" }}>
        <p
          style={{
            color: "black",
            textAlign: "left",
            marginBottom: 5,
            fontWeight: "bold",
            fontSize: 12,
            paddingLeft: 5,
            color: "#A9A9A9",
          }}
        >
          Email
        </p>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{
            width: "95%",
            height: 45,
            borderWidth: 0,
            paddingLeft: 8,
            borderBottomWidth: 1,
            borderBottomColor: "#A9A9A9",
          }}
        />
      </div>
      <div style={{ width: "80%", marginBottom: 20, marginTop: 20 }}>
        <p
          style={{
            color: "black",
            textAlign: "left",
            marginBottom: 5,
            fontWeight: "bold",
            fontSize: 12,
            paddingLeft: 5,
            color: "#A9A9A9",
          }}
        >
          Password
        </p>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          style={{
            width: "95%",
            height: 45,
            borderWidth: 0,
            paddingLeft: 8,
            borderBottomWidth: 1,
            borderBottomColor: "#A9A9A9",
          }}
        />
      </div>
      <div style={{ width: "80%", marginBottom: 20, marginTop: 20 }}>
        <p
          style={{
            color: "black",
            textAlign: "left",
            marginBottom: 5,
            fontWeight: "bold",
            fontSize: 12,
            paddingLeft: 5,
            color: "#A9A9A9",
          }}
        >
          Confirm Password
        </p>
        <input
          type="password"
          style={{
            width: "95%",
            height: 45,
            borderWidth: 0,
            paddingLeft: 8,
            borderBottomWidth: 1,
            borderBottomColor: "#A9A9A9",
          }}
        />
      </div>
      <div
        style={{
          width: "80%",
          marginBottom: 25,
          marginTop: 5,
          backgroundColor: "#862623",
          borderRadius: 20,
          height: 50,
          cursor: "pointer",
          alignItems: "center",
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={() => {
          createUser(auth, email, password);
          setDisplayIndicator(true);
        }}
      >
        <p style={{ color: "white", fontWeight: "bold" }}>Sign Up</p>
        {displayIndicator ? (
          <div style={{ position: "absolute", right: 30 }}>
            <Dots color="white" />
          </div>
        ) : (
          <></>
        )}
      </div>
      <p
        style={{
          color: "black",
          fontSize: 12,
          marginTop: 0,
          marginBottom: 25,
        }}
      >
        Already have an account?&nbsp;&nbsp;
        <Link to="/login" style={{ color: "black", textDecoration: "none" }}>
          <span
            style={{
              textDecorationLine: "underline",
              fontWeight: "bold",
            }}
          >
            Log In
          </span>
        </Link>
      </p>
    </div>
  );
}
