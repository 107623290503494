import React, { useState, useEffect } from "react";
import "../App.css";
import Course from "../components/Course";

export default function Home(props) {
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#f8f6f1",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <Course />
        </div>
      </div>
    </>
  );
}
