import "../App.css";
import React, { useState, useEffect, useRef } from "react";
import HeaderImage from "../assets/Logo.png";
import DownArrow from "../assets/down-arrow.png";
import { Link } from "react-router-dom";
import FiddleAnnual from "../assets/fiddleAnnual.webp";
import PrivateLessons from "../assets/Private-Lessons.webp";
import TexasTunes from "../assets/TexasTunesMay2024.webp";
import SwingImprov from "../assets/SwingImprovMay2024.webp";
import nightingale from "../assets/nightingale.webp";
import lockwood from "../assets/lockwood.webp";
import oldfashioned from "../assets/oldfashioned.webp";
import morningstar from "../assets/morningstar.webp";
import volume1 from "../assets/volume1.webp";
import volume2 from "../assets/volume2.webp";
import volume3 from "../assets/volume3.webp";
import completeSet from "../assets/completeSet.webp";

export default function Store(props) {
  const first = useRef(null);
  const second = useRef(null);
  const third = useRef(null);
  const fourth = useRef(null);
  const fifth = useRef(null);
  const sixth = useRef(null);

  const sections = ["Memberships", "Webinars", "Fiddles", "Books", "Lessons"];
  const scrollToSection = (index) => {
    let scroll = 0;
    if (index == 0) scroll = 0;
    else if (index == 1) {
      scroll = 575;
    } else if (index == 2) {
      scroll = 850;
    } else if (index == 3) {
      scroll = 1200;
    } else if (index == 4) {
      scroll = 1550;
    }
    console.log(scroll, index);
    window.scrollTo({
      top: scroll,
      behavior: "smooth",
    });
  };
  const [section, setSelectedSection] = useState(sections[0]);
  return (
    <>
      <div
        style={{
          top: 0,
          width: "100vw",
          //   height: "100vh",
          backgroundColor: "#f8f6f1",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          paddingTop: "14.5vh",
        }}
      >
        <div>
          <div
            style={{
              top: 0,
              position: "fixed",
              right: "0vw",
              width: "100vw",
              flexDirection: "row",
              display: "flex",
              marginTop: 0,
              backgroundColor: "#f8f6f1",
              zIndex: 1000,
              height: 70,
              alignItems: "center",
              borderBottom: "2.5px solid #847866",
            }}
          >
            <div style={{ flex: 1, alignItems: "flex-start", display: "flex" }}>
              <img
                src={HeaderImage}
                style={{ width: 140, marginRight: 20, marginLeft: 80 }}
              />
            </div>
            <div
              style={{
                marginRight: 80,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link
                to={"/"}
                onClick={() => {
                  props.scrollToSection(props.refs[2]);
                }}
                className={
                  props.selected == 2
                    ? "Header-Label Header-Selected"
                    : "Header-Label"
                }
                style={{ textDecorationLine: "none" }}
              >
                Home
              </Link>
              <div
                style={{
                  color: "black",
                  textDecoration: "none",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   props.scrollToSection(props.refs[2]);
                // }}
                className={"Header-Label"}
              >
                Store
              </div>
              <div
                onClick={() => {
                  props.scrollToSection(props.refs[3]);
                }}
                style={{
                  backgroundColor: "#832623",
                  width: 120,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: 20,
                  cursor: "pointer",
                }}
              >
                Login
              </div>
            </div>
          </div>
          <div
            style={{
              top: 70,
              position: "fixed",
              right: "0vw",
              width: "100vw",
              flexDirection: "row",
              display: "flex",
              marginTop: 0,
              backgroundColor: "#832216",
              zIndex: 1000,
              height: 50,
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "2.5px solid #847866",
            }}
          >
            {sections.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    // setSelectedSection(item);
                    let refs = [first, second, third, fourth, fifth, sixth];
                    scrollToSection(index);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    marginLeft: 20,
                    marginRight: 20,
                    fontWeight: item == section ? "bold" : "500",
                    fontSize: 14,
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
        {section == "Memberships" ? (
          <>
            <div
              ref={first}
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  marginRight: "1%",
                  height: 540,
                  width: "40%",
                  borderRadius: 5,
                  textAlign: "left",
                  paddingLeft: 25,
                  cursor: "pointer",
                  position: "relative",
                  border: "2px solid #c0c0c0",
                }}
                className="StoreButton Button"
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: 25,
                    fontSize: 15,
                    textTransform: "uppercase",
                  }}
                >
                  Signature Memberhsip
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: 18,
                    fontSize: 32,
                    marginTop: -12,
                  }}
                >
                  Fiddle Annual
                </div>
                <img
                  src={FiddleAnnual}
                  style={{ width: "75%", marginTop: 20, borderRadius: 5 }}
                />
                <div style={{ fontSize: 16, marginTop: 20, fontWeight: "600" }}>
                  <div style={{ marginTop: 2 }}>
                    • 1000+ lessons from experts
                  </div>
                  <div style={{ marginTop: 2 }}>
                    • 400+ tracks to listen and play along to
                  </div>
                  <div style={{ marginTop: 2 }}>
                    • Community of hundreds of fiddlers
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "#782a1d",
                    width: "100%",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    flexDirection: "row",
                    display: "flex",
                    height: 75,
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      borderRight: "1.5px solid white",
                    }}
                  >
                    <div style={{ marginTop: 2 }}>12 Month Sign Up</div>
                    <div style={{ marginTop: 2 }}> $35 / month</div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginTop: 2 }}> Month to Month</div>
                    <div style={{ marginTop: 2 }}> $45 / month</div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  marginLeft: "1%",
                  height: 540,
                  width: "40%",
                  borderRadius: 5,
                  textAlign: "left",
                  paddingLeft: 25,
                  cursor: "pointer",
                  position: "relative",
                  border: "2px solid #c0c0c0",
                }}
                className="StoreButton Button"
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: 25,
                    fontSize: 15,
                    textTransform: "uppercase",
                  }}
                >
                  Exclusive Membership
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: 18,
                    fontSize: 32,
                    marginTop: -12,
                  }}
                >
                  Golden Fiddle Club
                </div>
                <img
                  src={FiddleAnnual}
                  style={{ width: "75%", marginTop: 20, borderRadius: 5 }}
                />
                <div style={{ fontSize: 16, marginTop: 20, fontWeight: "600" }}>
                  <div style={{ marginTop: 2 }}>
                    • 1000+ lessons from experts
                  </div>
                  <div style={{ marginTop: 2 }}>• Access to all webinars</div>
                  <div style={{ marginTop: 2 }}>
                    • Discounts on available store items and camps
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "#51789f",
                    width: "100%",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    flexDirection: "row",
                    display: "flex",
                    height: 75,
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      borderRight: "1.5px solid white",
                    }}
                  >
                    <div style={{ marginTop: 2 }}>12 Month Sign Up</div>
                    <div style={{ marginTop: 2 }}> $35 / month</div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginTop: 2 }}> Month to Month</div>
                    <div style={{ marginTop: 2 }}> $45 / month</div>
                  </div>
                </div>
              </div>
            </div>
            <div ref={second}></div>
            <div
              style={{
                width: "100%",
                marginTop: 25,
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  width: "84.5%",
                  backgroundColor: "white",
                  borderRadius: 5,
                  textAlign: "left",
                  paddingLeft: 25,
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  border: "1.5px solid #c0c0c0",
                  flexDirection: "column",
                }}
                className="StoreButton"
              >
                <div
                  style={{
                    marginLeft: "1%",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingTop: 20,
                      fontSize: 16,
                      textTransform: "uppercase",
                      paddingBottom: 5,
                      //   textDecorationLine: "underline",
                    }}
                  >
                    Live Webinars With Katie Glassman
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      borderTop: "2px solid #c0c0c0",
                      paddingTop: 5,
                    }}
                  >
                    <div className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Texas Tunes 1
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 5,
                          fontWeight: "bold",
                        }}
                      >
                        $154.00
                      </div>
                    </div>
                    <div className="Button">
                      <img
                        src={SwingImprov}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Swing Improv 1
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 5,
                          fontWeight: "bold",
                        }}
                      >
                        $154.00
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "1%" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingTop: 30,
                      paddingBottom: 5,
                      fontSize: 16,
                      textTransform: "uppercase",
                      //   textDecorationLine: "underline",
                    }}
                    ref={fifth}
                  >
                    Fiddles
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      borderTop: "2px solid #c0c0c0",
                      paddingTop: 5,
                    }}
                  >
                    <div className="Button">
                      <img
                        src={morningstar}
                        style={{
                          width: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div>
                        Morningstar: Beginner
                        <br />
                        Fiddler Package
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $700.00
                      </div>
                    </div>
                    <div className="Button" style={{ marginLeft: 15 }}>
                      <img
                        src={oldfashioned}
                        style={{
                          width: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div>
                        The Old Fashioned
                        <br />
                        Electro-Acoustic
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $1600.00
                      </div>
                    </div>
                    <div className="Button">
                      <img
                        src={lockwood}
                        style={{
                          width: 140,
                          height: 210,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div>
                        The Lockwood
                        <br />
                        Electro-Acoustic
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $2400.00
                      </div>
                    </div>
                    <div className="Button">
                      <img
                        src={nightingale}
                        style={{
                          width: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div>
                        The Nightingale
                        <br />
                        Electro-Acoustic
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $2625.00
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "1%" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingTop: 30,
                      fontSize: 16,
                      textTransform: "uppercase",

                      paddingBottom: 5,
                      //   textDecorationLine: "underline",
                    }}
                  >
                    Fiddle School Books
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderTop: "2px solid #c0c0c0",
                    }}
                  >
                    <div
                      className="Button"
                      style={{
                        paddingTop: 5,
                        maxWidth: 175,
                      }}
                    >
                      <img
                        src={volume1}
                        style={{
                          width: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Fiddle School Book Volume 1 (New Edition!)
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $35.00
                      </div>
                    </div>
                    <div
                      style={{
                        paddingTop: 5,
                        maxWidth: 175,
                        overflow: "hidden",
                      }}
                      className="Button"
                    >
                      <img
                        src={volume2}
                        style={{
                          width: 140,
                          borderRadius: 2,
                          marginRight: 0,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Fiddle School Book Volume 2 (New Edition!)
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $35.00
                      </div>
                    </div>
                    <div
                      style={{
                        paddingTop: 5,
                        maxWidth: 175,
                      }}
                      className="Button"
                    >
                      <img
                        src={volume3}
                        style={{
                          width: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Fiddle School Book (Volume 3)
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $35.00
                      </div>
                    </div>
                    <div style={{ paddingTop: 5 }} className="Button">
                      <img
                        src={completeSet}
                        style={{
                          width: 200,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                          marginTop: 40,
                        }}
                      >
                        Fiddle School Book Bundle (Complete Set)
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $95.00
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: "1%" }} ref={sixth}>
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingTop: 30,
                      fontSize: 16,
                      textTransform: "uppercase",
                      display: "flex",
                      flexDirection: "row",
                      paddingBottom: 5,
                      //   textDecorationLine: "underline",
                    }}
                  >
                    Private Lessons
                  </div>
                  <div
                    style={{ paddingTop: 5, borderTop: "2px solid #c0c0c0" }}
                  >
                    <img
                      src={PrivateLessons}
                      style={{
                        width: 140,
                        height: 140,
                        borderRadius: 2,
                        marginRight: 20,
                        marginTop: 10,
                      }}
                    />
                    <div
                      style={{
                        width: "80%",
                        marginRight: 8,
                        fontSize: 14,
                        marginTop: 8,
                        fontWeight: "bold",
                      }}
                    >
                      $54.00-$90.00 / lesson
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "1%", marginBottom: 20 }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingTop: 30,
                      fontSize: 16,
                      textTransform: "uppercase",
                      display: "flex",
                      flexDirection: "row",
                      paddingBottom: 5,
                      //   textDecorationLine: "underline",
                    }}
                  >
                    Recorded & Archived Webinars
                  </div>
                  <div
                    style={{
                      paddingTop: 5,
                      borderTop: "2px solid #c0c0c0",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ maxWidth: 160 }} className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Irish Tunes: Learn a Complete Set
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $50.00
                      </div>
                    </div>
                    <div className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Listening Library
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div style={{ maxWidth: 160 }} className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Archived Improv Workouts
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Historical Interviews
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ maxWidth: 160 }} className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Archived Texas Tunes
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div style={{ maxWidth: 160 }} className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Guitar Backups 1
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div style={{ maxWidth: 160 }} className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Guitar Backups 2
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $125.00
                      </div>
                    </div>
                    <div style={{ maxWidth: 160 }} className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Jamming Skills 1
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $50.00
                      </div>
                    </div>
                  </div>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ maxWidth: 160 }} className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Jamming Skills 2
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $150.00
                      </div>
                    </div>
                    <div style={{ maxWidth: 160 }} className="Button">
                      <img
                        src={TexasTunes}
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 2,
                          marginRight: 20,
                          marginTop: 10,
                        }}
                      />
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        Theory 101: A Fiddler’s Toolkit
                      </div>
                      <div
                        style={{
                          width: "80%",
                          marginRight: 8,
                          fontSize: 14,
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        $150.00
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{
                    flex: 1,
                    alignItems: "flex-end",
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 30,
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingTop: 20,
                      fontSize: 16,
                      textTransform: "uppercase",
                      textAlign: "right",
                    }}
                  >
                    Camp & Retreats
                  </div>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: 250,
                      justifyContent: "center",
                      alignItems: "flex-end",
                      //   alignItems: "fl",
                      textAlign: "right",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "#6f2f22",
                        fontSize: 24,
                        marginTop: 15,
                      }}
                    >
                      Fiddle School Mountain Retreat
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: 16,
                        marginTop: 15,
                        textAlign: "right",
                      }}
                    >
                      August 22-24, 2024
                    </div>
                    <div
                      className="Button"
                      style={{
                        fontWeight: "bold",
                        marginTop: 20,
                        width: 160,
                        height: 40,
                        borderRadius: 18,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: "#6f2f22",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      Join wait list
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        marginTop: 15,
                      }}
                    >
                      If a spot opens up, we will contact you!
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
