import React, {
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useState,
} from "react";
import "../App.css";
import Calendar from "../assets/calendar.png";
import SMS from "../assets/sms.png";
import Play from "../assets/play.png";
import Pause from "../assets/pause.png";
import Circle from "../assets/Circle2.avif";
import Notepad from "../assets/notepad2.png";

import video1Screenshot from "../assets/video1Screenshot.png";

// import WaveSurfer from "wavesurfer.js";
import WaveSurfer from "wavesurfer.js";
import { useNavigate } from "react-router-dom";
export default function Profile(props) {
  const [speed, setSpeed] = useState(1);
  const [playPause, setPlayPause] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const audioURL = require("../assets/testing.mp3");
    const audio = new Audio(audioURL); // assets folder in root project folder for Vanilla JS
    console.log(audio);
    let preservePitch = true;
    const speeds = [0.25, 0.5, 1, 2, 4];
    const wavesurfer = WaveSurfer.create({
      container: "#Wave",
      height: 200,
      width: 300,
      waveColor: "white",
      progressColor: "#c0c0c0",
      audioRate: 1, // set the initial playback rate
    });
    wavesurfer.load(audioURL);
    wavesurfer.on("click", () => {
      wavesurfer.play();
    });

    // Set the playback rate
    // document
    //   .querySelector('input[type="range"]')
    //   .addEventListener("input", (e) => {
    //     const speed = speeds[e.target.valueAsNumber];
    //     document.querySelector("#rate").textContent = speed.toFixed(2);
    //     wavesurfer.setPlaybackRate(speed, preservePitch);
    //     wavesurfer.play();
    //   });
    // //   // Play/pause
    // document.querySelector("button").addEventListener("click", () => {
    //   wavesurfer.playPause();
    // });
  }, []);

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#f8f6f1",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100vw",
            // top: 50,
            top: 0,
            left: 0,
            backgroundColor: "#f0ebe0",
            height: 65,
            overflow: "hidden",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              marginLeft: 85,
              flex: 1,
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: 22 }}>
              Welcome Back, Kyle!
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/profile");
            }}
            style={{ marginRight: 65, cursor: "pointer", fontWeight: "bold" }}
          >
            Home
          </div>
          <div
            onClick={() => {
              navigate("/home");
            }}
            style={{ marginRight: 65, cursor: "pointer" }}
          >
            Videos
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            onClick={() => {
              navigate("/radio");
            }}
            style={{ marginRight: 65, cursor: "pointer" }}
          >
            Radio
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            // onClick={() => {
            //   navigate("/profile");
            // }}
            style={{ marginRight: 220, cursor: "pointer" }}
          >
            Community
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "14vh", paddingLeft: "8vh" }}>
          <div
            style={{
              background: "#73322B",
              color: "white",
              borderRadius: 8,
              width: 550,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: 32,
                textAlign: "left",
                marginBottom: 0,
                width: 450,
              }}
            >
              Progress
            </p>
            {/* <p
              style={{
                fontWeight: "bold",
                fontSize: 32,
                textAlign: "left",
                marginBottom: 0,
                width: 450,
                marginTop: 40,
              }}
            >
              Our Site Redesign
            </p>
            <div style={{ width: 450 }}>
              <p style={{ textAlign: "left", marginTop: 8 }}>
                We rebuilt the Fiddle School site to make the user experience
                better for you, and it's just gettting started! Everything you
                need can be accessed on this home page:
              </p>
              <p
                style={{
                  textAlign: "left",
                  marginBottom: 4,
                  marginTop: 6,
                  fontSize: 14,
                }}
              >
                <span style={{ fontWeight: "bold" }}>- The video catalog</span>{" "}
                includes an easy to navigate page with all of your course &
                lesson in one place.
              </p>
              <p
                style={{
                  textAlign: "left",
                  marginBottom: 4,
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                <span style={{ fontWeight: "bold" }}>- Fiddleschool Radio</span>{" "}
                provides a dedicated space to listen to our tunes and practice
                getting in the jam while you're not playing!
              </p>
              <p
                style={{
                  textAlign: "left",
                  marginBottom: 4,
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                <span style={{ fontWeight: "bold" }}>- Community</span> brings
                you to the familiar fiddleschool circle community.
              </p>
              <p
                style={{
                  textAlign: "left",
                  marginBottom: 4,
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                <span style={{ fontWeight: "bold" }}>- Goals and Notes</span>{" "}
                gives you a place to write about what you're learning, put down
                goals for the future, and track your progress!
              </p>
            </div> */}
            <div
              style={{
                marginTop: 25,
                textAlign: "left",
                width: "100%",
                marginLeft: 120,
                fontWeight: "bold",
              }}
            >
              Lesson 4 progress
            </div>
            <div
              style={{
                width: 436,
                height: 10,
                borderRadius: 8,
                backgroundColor: "#efebe1",
                marginTop: 10,
                border: "2px solid black",
              }}
            >
              <div
                style={{
                  backgroundColor: "#6b362e",
                  width: 100,
                  height: 10,
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                }}
              ></div>
            </div>
            <div style={{ display: "flex", color: "black", marginTop: 20 }}>
              <div
                style={{
                  height: 150,
                  marginRight: 8,
                  width: 210,
                  backgroundColor: "#efebe1",
                  borderRadius: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: 20,
                    paddingBottom: 25,
                  }}
                >
                  Days In a Row
                </div>
                <div style={{ fontSize: 28 }}>3</div>
              </div>
              <div
                style={{
                  marginLeft: 8,
                  height: 150,
                  width: 210,
                  backgroundColor: "#efebe1",
                  borderRadius: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: 20,
                    paddingBottom: 25,
                  }}
                >
                  Total Lesson Time
                </div>
                <div style={{ fontSize: 28 }}>26.5</div>
                <span style={{ fontSize: 12, marginTop: 20 }}>(Hours)</span>
              </div>
            </div>

            <div
              style={{
                height: 2,
                backgroundColor: "white",
                width: "100%",
                marginTop: 40,
              }}
            />

            <p
              style={{
                fontWeight: "bold",
                fontSize: 32,
                textAlign: "left",
                marginBottom: 0,
                width: 450,
              }}
            >
              Pro tip
            </p>
            <p style={{ width: 400, textAlign: "left", marginTop: 8 }}>
              Reaching your full potential as a fiddler takes time..
              <strong> Daily practice is essential!</strong> Try to carve out
              15-45 minutes per day practice your fundamentals - Set up calendar
              reminders or fiddleschool SMS reminders below.
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 14,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginRight: 8,
                  width: 210,
                  cursor: "pointer",
                  border: "1px solid white",
                  borderRadius: 4,
                  // marginRight: 4,
                }}
                className="Button"
              >
                <img src={SMS} style={{ width: 120 }} />

                <p>Text Reminder</p>
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginLeft: 8,
                  width: 210,
                  cursor: "pointer",
                  border: "1px solid white",
                  borderRadius: 4,
                }}
                className="Button"
              >
                <img src={Calendar} style={{ width: 120 }} />
                <p>Set Calendar Reminder</p>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "8vw" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  width: 350,
                  height: 350,
                  borderRadius: 4,
                  marginRight: 15,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #73322B",
                  cursor: "pointer",
                  backgroundColor: "#73322B",
                }}
                className="Button"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    marginBottom: 30,
                  }}
                >
                  <p style={{ color: "white" }}>Today's Lesson</p>

                  <div style={{ height: 200 }}>
                    <div style={{ height: 200, position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          width: 70,
                          height: 70,
                          top: 0,
                          zIndex: 100,
                          borderRadius: 40,
                          left: 135,
                          top: 65,
                          opacity: 0.8,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <img src={Play} style={{ width: 50, height: 50 }} />
                      </div>
                      <img
                        id={"blur"}
                        src={video1Screenshot}
                        style={{ width: 350, height: 200, borderRadius: 2 }}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        fontWeight: "normal",
                        marginTop: 15,
                        color: "white",
                      }}
                    >
                      <strong>Starter Kit:</strong>&nbsp;Unit 1, Video 1
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: 350,
                  height: 350,
                  borderRadius: 4,
                  marginLeft: 15,
                  backgroundColor: "#73322B",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #73322B",
                  cursor: "pointer",
                }}
                className="Button"
              >
                <div
                  style={{ fontWeight: "bold", fontSize: 22, color: "white" }}
                  onClick={() => {
                    navigate("/radio");
                  }}
                >
                  <p style={{ marginBottom: 25 }}>Fiddle School Radio</p>
                  <div style={{ marginTop: 0 }} id="Wave" />
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: "normal",
                      marginTop: 15,
                    }}
                  >
                    <strong>Play along:</strong> Dixie Hoedown with fast
                    guitar...
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 50 }}
            >
              <div
                style={{
                  width: 350,
                  height: 350,
                  borderRadius: 4,
                  marginRight: 15,
                  backgroundColor: "#73322B",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "3px solid #73322B",
                  cursor: "pointer",
                }}
                className="Button"
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    marginBottom: 30,
                    color: "white",
                  }}
                >
                  <p>Community</p>
                  <div
                    style={{
                      height: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={Circle} style={{ width: 150, height: 150 }} />
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: "normal",
                      marginTop: 25,
                    }}
                  >
                    Connect with other like minded Fiddlers!
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: 350,
                  height: 350,
                  borderRadius: 4,
                  marginLeft: 15,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #73322B",
                  backgroundColor: "#73322B",
                  cursor: "pointer",
                }}
                className="Button"
                onClick={() => {
                  navigate("/progress");
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    marginBottom: 30,
                    color: "white",
                  }}
                >
                  <p>Notes and Progress</p>
                  <div
                    style={{
                      height: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={Notepad} style={{ width: 150, height: 150 }} />
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: "normal",
                      marginTop: 25,
                    }}
                  >
                    Track your progress, reflect on your fiddle journey
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* 
<div>
<div
  style={{
    display: "flex",
    margin: "1rem 0",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  <button
    onClick={() => {
      setPlayPause(!playPause);
    }}
    style={{
      // backgroundColor: "black",
      // color: "white",
      // width: 100,
      // height: 50,
      borderRadius: 4,
      border: "none",
      cursor: "pointer",
      fontWeight: "bold",
      backgroundColor: "#f8f6f1",
    }}
  >
    {playPause ? (
      <img src={Pause} style={{ width: 25 }} />
    ) : (
      <img src={Play} style={{ width: 25 }} />
    )}
  </button> 
<div style={{ display: "flex", flexDirection: "column" }}>
    <label style={{ fontSize: 16 }}>
      0.25x{" "}
      <input
        onChange={(event) => {
          setSpeed(Number(event.target.value).toFixed(2));
        }}
        value={speed}
        type="range"
        min="0"
        max="4"
        step="1"
        style={{ width: 300, accentColor: "#7b2d28" }}
      />{" "}
      4x
    </label>
    <label style={{ fontSize: 12, marginTop: 8 }}>
      Playback rate:{" "}
      <span id="rate" style={{ fontWeight: "bold" }}>
        1.00
      </span>
      <span style={{ fontWeight: "bold" }}>x</span>
    </label>
  </div> 
<label>
<input type="checkbox" checked />
Preserve pitch
</label> </div>{" "}
</div>*/
}
