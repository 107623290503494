import React from "react";
import HeaderImage from "../assets/Logo.png";
import { Link } from "react-router-dom";

export default function HeaderSplash(props) {
  return (
    <>
      <div>
        <div
          style={{
            position: "fixed",
            left: "0vw",
            right: "10vw",
            width: "100vw",
            flexDirection: "row",
            display: "flex",
            marginTop: 0,
            backgroundColor: "#f8f6f1",
            zIndex: 1000,
            height: 80,
            alignItems: "center",
            borderBottom: "2.5px solid #847866",
          }}
        >
          <div style={{ flex: 1, alignItems: "flex-start", display: "flex" }}>
            <img
              src={HeaderImage}
              style={{ width: 140, marginRight: 20, marginLeft: 80 }}
            />
          </div>
          <div
            style={{
              marginRight: 80,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                props.scrollToSection(props.refs[0]);
              }}
              className={
                props.selected == 0
                  ? "Header-Label Header-Selected"
                  : "Header-Label"
              }
            >
              About
            </div>
            <div
              onClick={() => {
                props.scrollToSection(props.refs[1]);
              }}
              className={
                props.selected == 1
                  ? "Header-Label Header-Selected"
                  : "Header-Label"
              }
            >
              Our Method
            </div>
            <div
              onClick={() => {
                props.scrollToSection(props.refs[2]);
              }}
              className={
                props.selected == 2
                  ? "Header-Label Header-Selected"
                  : "Header-Label"
              }
            >
              Instructors
            </div>
            <Link
              to="store"
              style={{ color: "black", textDecoration: "none" }}
              // onClick={() => {
              //   props.scrollToSection(props.refs[2]);
              // }}
              className={"Header-Label"}
            >
              Store
            </Link>
            <div
              onClick={() => {
                props.scrollToSection(props.refs[3]);
              }}
              style={{
                backgroundColor: "#832623",
                width: 120,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
                borderRadius: 20,
                cursor: "pointer",
              }}
            >
              Login
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
