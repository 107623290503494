import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore/lite";
import React, { useState, useEffect } from "react";
import HeaderImage from "../assets/Logo.png";
import Hamburger from "../assets/Hamburger.png";
import User from "../assets/User.png";
// Import the functions you need from the SDKs you need
import "../App.css";
import { useNavigate } from "react-router-dom";
import Wave from "./Wave";
import "react-activity/dist/library.css";
import { S3 } from "@aws-sdk/client-s3";
import { Dots } from "react-activity";

const firebaseConfig = {
  apiKey: "AIzaSyAM-2T0bno25jigtMP4JWXd7y71nmXQzso",
  authDomain: "fiddleschool-6fa40.firebaseapp.com",
  projectId: "fiddleschool-6fa40",
  storageBucket: "fiddleschool-6fa40.appspot.com",
  messagingSenderId: "997131554190",
  appId: "1:997131554190:web:1b16cfa7a7e5b7a72868df",
  measurementId: "G-THQHHBHPFB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Course(props) {
  const classTypes = [
    { name: "Starter Kit", slug: "starterKit" },
    { name: "Restarter Kit", slug: "restarterKit" },
    { name: "Tune-up Kit", slug: "tuneUpKit" },
    { name: "Session 1: Texas Fiddle Foundations", slug: "sessionOne" },
    { name: "Session 2: The Seasoned Fiddler", slug: "sessionTwo" },
    { name: "Session 3: Advancing Fiddlers", slug: "sessionThree" },
    { name: "Session 4: Reach Your Next Level", slug: "sessionFour" },
  ];

  const [playAlongTracks, setPlayAlongTracks] = useState([]);
  const [selectedPlayAlong, setSelectedPlayAlong] = useState(0);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);

  const [loggedIn, setLoggedIn] = useState(false);
  const [courses, setCourses] = useState([]);
  const [currentCourse, setCurrentCourse] = useState([]);
  const [videoSelection, setVideoSelection] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const [numberOfUnits, setNumberOfUnits] = useState([0, 0]);
  const [videoIndex, setVideoIndex] = useState(0);
  const [currentVideo, setCurrentVideo] = useState("video1");
  const [currentUnit, setCurrentUnit] = useState(0);
  const [currentSelectedUnit, setCurrentSelectedUnit] = useState(0);

  const [videos, setVideos] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [selectedKit, setSelectedKit] = useState(0);
  const [browseKit, setBrowseKit] = useState(0);
  const [currentSlug, setCurrentSlug] = useState("starterKit");
  const [lessonStart, setLessonStart] = useState(0);
  const [lessonUnitActive, setLessonUnitActive] = useState(null);
  const [lessonUnits, setLessonUnits] = useState(null);
  const [lesson, setLesson] = useState(0);
  const [course, setCourse] = useState(0);

  function toggleSidebar() {
    document.querySelector("#sidebar").classList.toggle("closed");
    document.querySelector("#video-container").classList.toggle("closed");
    setSidebarOpen(!sidebarOpen);
  }

  const navigate = useNavigate();

  useEffect(() => {
    retrieveCourses();
  }, []);

  useEffect(() => {
    setVideoIndex(0);
    setCurrentVideo("video1");
    setIsLoadingVideo(false);
  }, [videos]);

  // useEffect(() => {
  //   if (videos != null && videoIndex > videos.length) {
  //     console.log("here");
  //   }
  // }, [videoIndex]);

  const getCourseByName = (courses, name) => {
    let coursesFromName = courses.filter((item) => item.name == name);
    // console.log(coursesFromName[0]);
    return coursesFromName[0];
  };

  const retrieveCourses = async () => {
    const courses = await getCourse(db);
    setCourses(courses);
    // number of units in the course
    let course = getCourseByName(courses, "starterKit");
    const units = Object.keys(course.data.units).length - 1;
    setNumberOfUnits(Array(units).fill(0));
    const unitString = "unit" + (currentUnit + 1);
    // const videosInUnit = Object.keys(course.data.units[unitString]).length;
    setVideos(course.data.units[unitString]);
    setIsLoadingVideo(false);
  };

  const changeCourse = (slug, introKit) => {
    setCurrentSlug(slug);
    let course = getCourseByName(courses, slug);
    if (course) {
      if (introKit == false) {
        // console.log("session 1 :: ", course);
        setNumberOfUnits(Array(course.data.lessonAmount).fill(0));
        setLessonStart(course.data.lessonStart);
        if (true) {
          const lessonString = "lesson" + (currentUnit + 1);
          let videoString = "video";
          if (currentUnit != 0) videoString += currentUnit + 1;
          console.log("lesson string ", lessonString);
          setLessonUnits(course.data.lessons["lesson1"].sections);
          if (course.data[videoString]) {
            setPlayAlongTracks(course.data[videoString].playAlong);
          } else {
            setPlayAlongTracks(course.data["video"].playAlong);
          }
          setSelectedPlayAlong(0);
        }
      } else {
        const units = Object.keys(course.data.units).length - 1;
        setNumberOfUnits(Array(units).fill(0));
        setCurrentUnit(0);
        setVideos(course.data.units["unit1"]);
        setCurrentUnit(0);
      }
    } else {
      alert("course is not good");
    }
  };

  const changeUnit = (unit) => {
    let course = getCourseByName(courses, currentSlug);
    const unitString = "unit" + (unit + 1);
    setVideos(course.data.units[unitString]);
  };

  const changeLesson = (unit) => {
    let course = getCourseByName(courses, currentSlug);
    const lessonString = "lesson" + (unit + 1);
    setLessonUnits(course.data.lessons[lessonString].sections);
    let videoString = "video";
    if (currentUnit != 0) videoString += currentUnit + 1;
    if (course.data && videoString && course.data[videoString]) {
      setPlayAlongTracks(course.data[videoString].playAlong);
    } else {
      console.log(videoString);
    }
    setSelectedPlayAlong(0);
    setSelectedTab(0);
    // let lessonVidSelection = course.data[videoString];
    // let vids = lessonVidSelection["practiceBuddies"];
    // setVideos(vids);
    // setVideos(course.data.lessons[lessonString]);
    // setLessonStart(unit);
  };

  async function getCourse(db) {
    const courseCollection = collection(db, "courses");
    const courseSnapshot = await getDocs(courseCollection);
    const courseList = courseSnapshot.docs.map((doc) => {
      return { name: doc.id, data: doc.data() };
    });
    // const courseList = courseSnapshot.docs.map((doc) => doc.data());
    return courseList;
  }
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100vw",
            // top: 50,
            top: 0,
            left: 0,
            backgroundColor: "#f0ebe0",
            height: 65,
            overflow: "hidden",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            onClick={() => {
              toggleSidebar();
            }}
            style={{
              marginLeft: 75,
              display: "flex",
              alignItems: "flex-start",
              flex: 1,
              // backgroundColor: "orange",
              // position: "absolute",
              // left: 50,
              // top: 50,
              // cursor: "pointer",
            }}
          >
            {!sidebarOpen ? (
              <img
                src={Hamburger}
                style={{ width: 32, height: 32, cursor: "pointer" }}
              />
            ) : (
              <></>
            )}
          </div>
          <div
            onClick={() => {
              navigate("/profile");
            }}
            style={{ marginRight: 65, cursor: "pointer" }}
          >
            Home
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            // onClick={() => {
            //   navigate("/profile");
            // }}
            style={{ marginRight: 65, fontWeight: "bold", cursor: "pointer" }}
          >
            Videos
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            onClick={() => {
              navigate("/radio");
            }}
            style={{ marginRight: 65, cursor: "pointer" }}
          >
            Radio
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
          <div
            // onClick={() => {
            //   navigate("/profile");
            // }}
            style={{ marginRight: 220, cursor: "pointer" }}
          >
            Community
            {/* <img
              src={User}
              style={{ width: 50, height: 50, marginRight: 75 }}
            /> */}
          </div>
        </div>
        <div id="sidebar">
          <img src={HeaderImage} style={{ width: 300 }} />
          <div style={{ flexDirection: "row", display: "flex", height: 10 }}>
            {/* <form id="search-form" role="search" style={{ marginRight: 0 }}>
              <input
                id="q"
                aria-label="Search contacts"
                placeholder="Search"
                type="search"
                name="q"
              />
              <div id="search-spinner" aria-hidden hidden={true} />
              <div className="sr-only" aria-live="polite"></div>
            </form> */}
            <div
              onClick={() => {
                toggleSidebar();
              }}
              className="close-toggle"
              style={{
                position: "absolute",
                right: 20,
                // border: "1px solid black",
                width: 22,
                height: 22,
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
                fontWeight: "bold",
              }}
            >
              x
            </div>
          </div>
          <nav style={{ cursor: "pointer" }}>
            <div
              style={{
                fontWeight: "bold",
                textAlign: "left",
                fontSize: 14,
                marginBottom: 8,
              }}
            >
              Intro Kits
            </div>
            <div>
              {classTypes.slice(0, 3).map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        backgroundColor:
                          selectedKit == index ? "#862623" : "transparent",
                        color: selectedKit == index ? "white" : "black",
                        borderRadius: 8,
                        cursor: "pointer",
                        marginTop: 10,
                      }}
                      onClick={() => {
                        changeCourse(item.slug, true);
                        setSelectedKit(index);
                      }}
                    >
                      <a>{item.name}</a>
                    </div>
                    {selectedKit == index ? (
                      <div>
                        {numberOfUnits.map((item, index) => {
                          return (
                            <div
                              onClick={() => {
                                setCurrentUnit(index);
                                changeUnit(index);
                              }}
                              style={{
                                backgroundColor:
                                  currentUnit == index
                                    ? "#c0c0c0"
                                    : "transparent",
                                height: 30,
                                marginTop: 10,
                                marginLeft: 20,
                                marginRight: 5,
                                borderRadius: 6,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <a
                                style={{
                                  zIndex: 25,
                                  cursor: "pointer",
                                  color: "black",
                                  fontSize: 18,
                                  opacity: currentUnit == index ? 1 : 0.5,
                                  fontWeight:
                                    currentUnit == index ? "bold" : "normal",
                                  marginBottom: 0,
                                  marginTop: 0,
                                  fontSize: 14,
                                  textAlign: "left",
                                  width: 240,
                                  height: 30,
                                  paddingLeft: 15,
                                }}
                              >
                                Unit {index + 1}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
            <div
              style={{
                fontWeight: "bold",
                textAlign: "left",
                fontSize: 14,
                marginBottom: 8,
                marginTop: 20,
              }}
            >
              Classes
            </div>
            <ul>
              {classTypes.slice(3, 7).map((item0, index0) => {
                return (
                  <>
                    {" "}
                    <li
                      style={{
                        backgroundColor:
                          selectedKit == index0 + 3 ? "#862623" : "transparent",
                        color: selectedKit == index0 + 3 ? "white" : "black",
                        borderRadius: 8,
                        cursor: "pointer",
                      }}
                    >
                      <a
                        onClick={() => {
                          changeCourse(item0.slug, false);
                          if (selectedKit < 3) {
                            setSelectedKit(index0 + 3);
                            setBrowseKit(index0 + 3);
                          } else {
                            setBrowseKit(index0 + 3);
                          }
                        }}
                      >
                        {item0.name}
                      </a>
                    </li>
                    {browseKit == index0 + 3 ? (
                      <div>
                        {numberOfUnits.map((item, index1) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  setCurrentUnit(index1);
                                  changeLesson(index1);
                                  setVideoIndex(0);
                                  setVideoSelection(0);
                                  setLesson(index1);

                                  // const value = "practiceBuddies";
                                  // let lessonValue = "video";
                                  // if (lesson == 0) {
                                  // } else if (currentSlug == "sessionOne") {
                                  //   lessonValue += lesson + 1;
                                  // } else {
                                  //   lessonValue += lesson;
                                  // }

                                  // const courseByName = getCourseByName(
                                  //   courses,
                                  //   currentSlug
                                  // );

                                  // let lessonVidSelection =
                                  //   courseByName.data[lessonValue];

                                  // let vids = lessonVidSelection[value];
                                  // // if (
                                  // //   lessonVidSelection[value] == undefined &&
                                  // //   index == 0
                                  // // ) {
                                  // //   vids = lessonVidSelection["rythym"];
                                  // // } else if (
                                  // //   lessonVidSelection[value] == undefined &&
                                  // //   index == 1
                                  // // ) {
                                  // //   vids = lessonVidSelection["language"];
                                  // // }
                                  // setVideos(vids);

                                  // let videoString = "video";
                                  // if (currentUnit == 0) {
                                  // } else if (selectedKit == 3) {
                                  //   videoString += currentUnit + 1;
                                  // } else {
                                  //   videoString += currentUnit;
                                  // }
                                  // console.log(
                                  //   videoString,
                                  //   courseByName.data[videoString].playAlong
                                  // );
                                  // setPlayAlongTracks(
                                  //   courseByName.data[videoString].playAlong
                                  // );
                                }}
                                style={{
                                  // backgroundColor:
                                  //   currentUnit == index
                                  //     ? "#c0c0c0"
                                  //     : "transparent",
                                  height: 30,
                                  marginTop: 10,
                                  marginLeft: 20,
                                  marginRight: 5,
                                  borderRadius: 6,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <a
                                  style={{
                                    zIndex: 25,
                                    cursor: "pointer",
                                    color: "black",
                                    fontSize: 18,
                                    opacity:
                                      currentSelectedUnit == index1 &&
                                      selectedKit == index0 + 3
                                        ? 1
                                        : 0.5,
                                    fontWeight:
                                      currentSelectedUnit == index1 &&
                                      selectedKit == index0 + 3
                                        ? "bold"
                                        : "normal",
                                    marginBottom: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    textAlign: "left",
                                    width: 240,
                                    height: 30,
                                    paddingLeft: 15,
                                  }}
                                >
                                  Lesson {index1 + lessonStart + 1}
                                </a>
                              </div>
                              {currentUnit == index1 ? (
                                <div>
                                  {lessonUnits.map((item, index) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          // changeLesson(index);
                                          if (
                                            videoSelection == index &&
                                            currentSelectedUnit == index1
                                          ) {
                                          } else {
                                            setSelectedKit(index0 + 3);
                                            setBrowseKit(index0 + 3);
                                            setIsLoadingVideo(true);
                                            setVideoSelection(index);
                                            setCurrentSelectedUnit(index1);
                                            setVideoIndex(0);
                                            const value =
                                              index == 0
                                                ? "practiceBuddies"
                                                : index == 1
                                                ? "listen"
                                                : index == 2
                                                ? "song1"
                                                : "song2";

                                            let lessonValue = "video";
                                            if (lesson == 0) {
                                            } else if (
                                              currentSlug == "sessionOne"
                                            ) {
                                              lessonValue += lesson + 1;
                                            } else {
                                              lessonValue += lesson;
                                            }

                                            const courseByName =
                                              getCourseByName(
                                                courses,
                                                currentSlug
                                              );

                                            let lessonVidSelection =
                                              courseByName.data[lessonValue];

                                            let vids =
                                              lessonVidSelection[value];
                                            if (
                                              lessonVidSelection[value] ==
                                                undefined &&
                                              index == 0
                                            ) {
                                              vids =
                                                lessonVidSelection["rythym"];
                                            } else if (
                                              lessonVidSelection[value] ==
                                                undefined &&
                                              index == 1
                                            ) {
                                              vids =
                                                lessonVidSelection["language"];
                                            }

                                            setVideos(vids);
                                            setIsLoadingVideo(false);
                                            let videoString = "video";
                                            if (currentUnit == 0) {
                                            } else if (selectedKit == 3) {
                                              videoString += currentUnit + 1;
                                            } else {
                                              videoString += currentUnit;
                                            }

                                            setPlayAlongTracks(
                                              courseByName.data[videoString]
                                                .playAlong
                                            );
                                          }
                                          // setVideos(lessonVidSelection[value]);
                                        }}
                                        style={{
                                          backgroundColor:
                                            videoSelection == index &&
                                            currentSelectedUnit == index1 &&
                                            selectedKit == index0 + 3
                                              ? "#c0c0c0"
                                              : "transparent",
                                          height: 30,
                                          marginTop: 10,
                                          marginLeft: 20,
                                          marginRight: 5,
                                          borderRadius: 6,
                                          marginLeft: 50,
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <a
                                          style={{
                                            zIndex: 25,
                                            cursor: "pointer",
                                            color: "black",
                                            fontSize: 18,
                                            opacity:
                                              videoSelection == index &&
                                              currentSelectedUnit == index1 &&
                                              selectedKit == index0 + 3
                                                ? 1
                                                : 0.5,
                                            fontWeight:
                                              videoSelection == index &&
                                              currentSelectedUnit == index1 &&
                                              selectedKit == index0 + 3
                                                ? "bold"
                                                : "normal",
                                            marginBottom: 0,
                                            marginTop: 0,
                                            fontSize: 14,
                                            textAlign: "left",
                                            width: 240,
                                            height: 30,
                                            paddingLeft: 15,
                                          }}
                                        >
                                          {lessonUnits[index].name}
                                        </a>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </ul>
            {/* <div
              style={{
                fontWeight: "bold",
                textAlign: "left",
                fontSize: 14,
                marginBottom: 8,
                marginTop: 20,
              }}
            >
              Archives
            </div> */}
            {/* <ul>
              <li
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  borderRadius: 8,
                }}
              >
                <a href={`/contacts/1`}>Mastery 1</a>
              </li>
              <li>
                <a href={`/contacts/2`}>Mastery 2</a>
              </li>
              <li>
                <a href={`/contacts/2`}>Mastery 3</a>
              </li>
              <li>
                <a href={`/contacts/2`}>Mastery 4</a>
              </li>
              <li>
                <a href={`/contacts/2`}>Archives</a>
              </li>
            </ul> */}
          </nav>
        </div>

        <script src="https://player.vimeo.com/api/player.js"></script>

        <div id={"video-container"} style={{ padding: 10 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: 30,
            }}
          >
            <div
              onClick={() => {
                setSelectedTab(0);
              }}
              className="Button"
              style={{
                width: selectedKit < 3 ? "50%" : "33%",
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                // opacity: selectedTab == 0 ? 1 : 0.5,
                backgroundColor: selectedTab == 0 ? "#7b2d28" : "#b8b8b8",
                color: "white",
                fontWeight: selectedTab == 0 ? "bold" : "normal",
                cursor: "pointer",
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
              }}
            >
              Videos
            </div>
            <div
              className="Button"
              onClick={() => {
                setSelectedTab(1);
              }}
              style={{
                cursor: "pointer",
                height: 50,
                display: selectedKit < 3 ? "none" : "flex",
                width: "33%",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                // opacity: selectedTab == 1 ? 1 : 0.5,
                fontWeight: selectedTab == 1 ? "bold" : "normal",
                backgroundColor: selectedTab == 1 ? "#7b2d28" : "#b8b8b8",
                border: "2px solid #7b2d28",
                borderLeft: selectedTab == 0 ? "none" : "2px solid white",
                borderRight: selectedTab == 2 ? "none" : "2px solid white",
                borderTop: "none",
                borderBottom: "none",
              }}
            >
              Play Along Tracks
            </div>
            <div
              className="Button"
              onClick={() => {
                // setSelectedTab(2);
                alert("Handouts PDF player still being built in");
              }}
              style={{
                width: selectedKit < 3 ? "50%" : "33%",
                height: 50,
                display: "flex",
                color: "white",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: selectedTab == 2 ? "bold" : "normal",
                // opacity: selectedTab == 2 ? 1 : 0.5,
                backgroundColor: selectedTab == 2 ? "#7b2d28" : "#b8b8b8",
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
              }}
            >
              Handouts
            </div>
          </div>
          {/* <div style={{ fontWeight: "bold", fontSize: 52, color: "#3e3125" }}>
            {classTypes[selectedKit].name}
          </div> */}
          {selectedTab == 0 ? (
            <div style={{ width: 1162, minHeight: 691, position: "relative" }}>
              <div
                style={{
                  height: 50,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  backgroundColor: "#f8f6f1",
                  zIndex: -20,
                }}
              ></div>

              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginRight: 75 }}>
                  <div>
                    <p
                      style={{
                        color: "black",
                        textAlign: "left",
                        marginBottom: 0,
                        marginTop: 0,
                        fontWeight: "bold",
                        fontSize: 32,
                      }}
                    >
                      Video {videoIndex + 1} -{" "}
                      {videos &&
                      videos[currentVideo] &&
                      !isLoadingVideo &&
                      videos[currentVideo].title ? (
                        videos[currentVideo].title
                      ) : (
                        <></>
                      )}
                    </p>
                  </div>
                  <div
                    style={{
                      width: 800,
                      height: 525,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {videos && videos[currentVideo] && !isLoadingVideo ? (
                      <iframe
                        src={
                          "https://player.vimeo.com/video/" +
                          videos[currentVideo].id +
                          "?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                        }
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        // style="position:absolute;top:0;left:0;width:100%;height:100%;"
                        style={{ width: 800, height: 525 }}
                        title="Texas Quick Step - A Part Section 1"
                      />
                    ) : (
                      <>
                        <Dots
                          size={24}
                          style={{ paddingBottom: 125 }}
                          color={"#682e27"}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div style={{ height: 500, overflow: "scroll" }}>
                  <div
                    style={{
                      textAlign: "left",
                      color: "black",
                      fontWeight: "bold",
                      marginBottom: 10,
                      fontSize: 18,
                    }}
                  >
                    Browse Lesson {currentUnit + 1} Videos
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {videos != null && videos != undefined ? (
                      Object.keys(videos).map((item, index) => {
                        const videoString = "video" + (index + 1);

                        return (
                          <div
                            className="Button"
                            style={{
                              backgroundColor: "#7b2d28",
                              minHeight: 70,
                              borderRadius: 4,
                              marginBottom: 4,
                              marginTop: 4,
                              paddingLeft: 10,
                              paddingRight: 25,
                              width: 240,
                              alignItems: "center",
                              display: "flex",
                              cursor: "pointer",
                              borderColor: "black",
                              borderStyle: "solid",
                              borderWidth: index == videoIndex ? 3.5 : 0,
                              position: "relative",
                            }}
                            onClick={() => {
                              setCurrentVideo(videoString);
                              setVideoIndex(index);
                            }}
                          >
                            <p
                              style={{
                                color: "white",
                                textAlign: "left",
                                marginBottom: 2,
                                marginTop: 2,
                                fontSize: 14,
                                fontSize: 16,
                                width: 210,
                                fontWeight:
                                  index == videoIndex ? "700" : "normal",
                              }}
                            >
                              {videos[videoString] && videos[videoString].title
                                ? videos[videoString].title
                                : ""}
                            </p>
                            <div
                              style={{
                                width: 18,
                                height: 18,
                                backgroundColor: "#efefef",
                                borderRadius: 20,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                right: 15,
                              }}
                            >
                              {index == 0 || index == 1 ? (
                                <p
                                  style={{
                                    color: "#862623",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontWeight: "bold",
                                    fontSize: 12,
                                  }}
                                >
                                  &#x2713;
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 5,
                  position: "absolute",
                  bottom: -75,
                  height: 75,
                  width: 1150,
                }}
              >
                <div
                  onClick={() => {
                    const videoString = "video" + (videoIndex - 1);
                    if (videoIndex > 0) {
                      if (videoIndex == 1) {
                        setCurrentVideo("video1");
                        setVideoIndex(0);
                      } else {
                        setCurrentVideo(videoString);
                        setVideoIndex(videoIndex - 1);
                      }
                    }
                  }}
                  className="Button"
                  style={{
                    width: "50%",
                    height: 85,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "#7b2d28",
                    backgroundColor: "#b8b8b8",
                    color: "white",
                    fontWeight: "bold",
                    cursor: "pointer",
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                  }}
                >
                  Previous
                </div>

                <div
                  className="Button"
                  onClick={() => {
                    console.log(videoIndex, videos);
                    if (videoIndex + 1 < Object.keys(videos).length) {
                      setVideoIndex(videoIndex + 1);
                      let videoString = "video" + (videoIndex + 2);
                      if (videoIndex == 0)
                        videoString = "video" + (videoIndex + 2);
                      setCurrentVideo(videoString);
                    } else {
                      alert(
                        "Please go on to next lesson through the sidebar. Need to alter the funcitonality here. Think there should be a congrats screen when you finish a lesson"
                      );
                    }
                  }}
                  style={{
                    width: "50%",
                    height: 85,
                    display: "flex",
                    color: "white",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    backgroundColor: "#7b2d28",
                    backgroundColor: "#b8b8b8",
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    borderLeft: "2px solid white",
                  }}
                >
                  Complete
                </div>
              </div>
            </div>
          ) : selectedTab == 1 ? (
            <div
              style={{
                width: 1162,
                minHeight: 691,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  marginTop: 0,
                  paddingTop: 50,
                }}
              >
                <div style={{ marginRight: 75 }}>
                  <div>
                    <p
                      style={{
                        color: "black",
                        textAlign: "left",
                        marginBottom: 0,
                        marginTop: 0,
                        fontWeight: "bold",
                        fontSize: 32,
                        marginBottom: 50,
                      }}
                    >
                      {playAlongTracks[selectedPlayAlong].name}
                    </p>
                  </div>
                  <Wave
                    playAlongTracks={playAlongTracks}
                    height={"small"}
                    selectedPlayAlong={selectedPlayAlong}
                    lesson={lessonStart + currentUnit + 1}
                  />
                </div>
                <div style={{ height: 600, overflow: "scroll" }}>
                  <div
                    style={{
                      textAlign: "left",
                      color: "black",
                      fontWeight: "bold",
                      marginBottom: 10,
                      fontSize: 18,
                    }}
                  >
                    Browse Tracks
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {playAlongTracks ? (
                      Object.keys(playAlongTracks).map((item, index) => {
                        const videoString = "video" + (index + 1);

                        return (
                          <div
                            style={{
                              backgroundColor: "#7b2d28",
                              minHeight: 70,
                              borderRadius: 4,
                              marginBottom: 4,
                              marginTop: 4,
                              paddingLeft: 10,
                              paddingRight: 25,
                              width: 225,
                              alignItems: "center",
                              display: "flex",
                              cursor: "pointer",
                              borderColor: "black",
                              borderStyle: "solid",
                              borderWidth: index == selectedPlayAlong ? 3.5 : 0,
                              position: "relative",
                            }}
                            onClick={() => {
                              // setCurrentVideo(videoString);
                              // setVideoIndex(index);
                              setSelectedPlayAlong(index);
                            }}
                          >
                            <p
                              style={{
                                color: "white",
                                textAlign: "left",
                                marginBottom: 2,
                                marginTop: 2,
                                fontSize: 14,
                                fontSize: 16,
                                width: 210,
                                fontWeight:
                                  index == selectedPlayAlong ? "700" : "normal",
                              }}
                            >
                              {playAlongTracks[index].name}
                            </p>
                            <div
                              style={{
                                width: 18,
                                height: 18,
                                backgroundColor: "#efefef",
                                borderRadius: 20,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                right: 15,
                              }}
                            >
                              {index == 0 || index == 1 ? (
                                <p
                                  style={{
                                    color: "#862623",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontWeight: "bold",
                                    fontSize: 12,
                                  }}
                                >
                                  &#x2713;
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 50,
                  width: "100%",
                  position: "absolute",
                  bottom: -75,
                  height: 75,
                  width: 1150,
                }}
              >
                <div
                  onClick={() => {
                    if (selectedPlayAlong > 0) {
                      setSelectedPlayAlong(selectedPlayAlong - 1);
                    }
                  }}
                  className="Button"
                  style={{
                    width: "50%",
                    height: 75,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "#7b2d28",
                    backgroundColor: "#b8b8b8",
                    color: "white",
                    fontWeight: "bold",
                    cursor: "pointer",
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                  }}
                >
                  Previous
                </div>

                <div
                  className="Button"
                  onClick={() => {
                    console.log(
                      selectedPlayAlong,
                      Object.keys(playAlongTracks).length
                    );
                    if (
                      selectedPlayAlong + 1 >=
                      Object.keys(playAlongTracks).length
                    ) {
                      alert(
                        "Congrats, you finished the play along tracks for this lesson. We will need to add a congrats screen here."
                      );
                    } else {
                      setSelectedPlayAlong(selectedPlayAlong + 1);
                    }
                  }}
                  style={{
                    width: "50%",
                    height: 75,
                    display: "flex",
                    color: "white",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    backgroundColor: "#7b2d28",
                    backgroundColor: "#b8b8b8",
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    borderLeft: "2px solid white",
                  }}
                >
                  Next
                </div>
              </div>
            </div>
          ) : (
            <div style={{ width: 1120, height: 691 }}>
              <div style={{ height: 50 }}></div>
              <p
                style={{
                  color: "black",
                  textAlign: "left",
                  marginBottom: 0,
                  marginTop: 0,
                  fontWeight: "bold",
                  fontSize: 32,
                  paddingtop: 50,
                }}
              >
                Handouts
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Course;
